// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login.tsx");
  import.meta.hot.lastModified = "1730741313745.0747";
}
// REMIX HMR END

import { json, redirect } from "@remix-run/node";
import { Form } from "@remix-run/react";
import { getUserId, login } from "../server/auth.server";
export const loader = async ({
  request
}) => {
  const userId = await getUserId(request);
  if (userId) {
    return redirect("/");
  }
  return json({});
};
export default function Login() {
  return <div className="grid grid-cols-1 justify-items-center">
      <Form method="post" className="w-96">
          <div className="form-control">
            <label className="label">
              <span className="label-text">Login</span>
            </label>
            <input name="login" className="input input-bordered" />
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Hasło</span>
            </label>
            <input name="password" type="password" className="input input-bordered" />
          </div>

          <div className="mt-6 flex items-center justify-end">
            <button type="submit" className="btn">Zaloguj</button>
          </div>
      </Form>
    </div>;
}
_c = Login;
export const action = async ({
  request
}) => {
  const formData = await request.formData();
  const username = formData.get("login");
  const password = formData.get("password");
  return login(request, username, password);
};
var _c;
$RefreshReg$(_c, "Login");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;